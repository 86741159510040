
import { React, useEffect, useRef, useState, forwardRef } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import {
    Box, Paper, CircularProgress, Backdrop
} from '@mui/material';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import {
    DataGridPro,
    GridToolbar
} from '@mui/x-data-grid-pro';
import axios from 'axios';
import dayjs from 'dayjs';

import { socket } from './socket';

var api = axios.create({ withCredentials: true });

const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 2,
    minimumFractionDigits: 2
}).format;

const numberFormatter = new Intl.NumberFormat('en-US', {
    maximumFractionDigits: 0,
    minimumFractionDigits: 0
}).format;

function Commissions(props) {
    const { getAccessTokenSilently } = useAuth0();
    const [broker_loading, setBrokerLoading] = useState(true);
    const [columns, setColumns] = useState(null);
    const [rows, setRows] = useState(null);
    const user = props.user;
    const isConnected = props.isConnected;

    function getData(controller) {
        getAccessTokenSilently({
            audience: process.env.REACT_APP_AUTH0AUDIENCE,
            scope: process.env.REACT_APP_AUTH0SCOPE
        }).then((token) => {
            api.get(
                `${process.env.REACT_APP_BASEURL}/api/datagrid/commissions/2024-10-14`,
                { signal: controller.signal, headers: { Authorization: `Bearer ${token}` } }
            ).then((response) => {
                const data = response.data.map((item, index) => ({
                    id: index,
                    paystart: dayjs(item._id.period_start.replace(/[Z]$/g, '')).toDate(),
                    payend: dayjs(item._id.period_end_date.replace(/[Z]$/g, '')).toDate(),
                    paydate: dayjs(item._id.pay_date.replace(/[Z]$/g, '')).toDate(),
                    buyerName: item.bought_from_name,
                    totalTicketCost: currencyFormatter(item.total_ticket_cost),
                    totalConsignmentFees: currencyFormatter(item.total_consignment_fees_per_ticket),
                    totalPoPayment: currencyFormatter(item.total_po_payment_per_ticket),
                    totalPoBalanceDue: currencyFormatter(item.total_po_balance_due_per_ticket)
                }));

                var columns = [
                    { field: 'paystart', headerName: 'Pay Period Start', flex: .35, minWidth: 100, type: 'date' },
                    { field: 'payend', headerName: 'Pay Period End', flex: .35, minWidth: 100, type: 'date' },
                    { field: 'paydate', headerName: 'Pay Date', flex: .35, minWidth: 100, type: 'date' },
                    { field: 'buyerName', headerName: 'Bought From Name', flex: .5, minWidth: 100, type: 'string' },
                    { field: 'totalTicketCost', headerName: 'Ticket Cost', flex: .25, minWidth: 100, type: 'number' },
                    { field: 'totalConsignmentFees', headerName: 'Commission', flex: .25, minWidth: 100, type: 'number' },
                    { field: 'totalPoPayment', headerName: 'PO Payments', flex: .25, minWidth: 100, type: 'number' },
                    { field: 'totalPoBalanceDue', headerName: 'PO Balance Due', flex: .25, minWidth: 100, type: 'number' }
                ];

                setRows(data);
                setColumns(columns);
                setBrokerLoading(false);
            }).catch((err) => {
                if (err.message !== 'canceled') {
                    console.log(err);
                    setBrokerLoading(false);
                }
            });
        });
    }

    useEffect(() => {
        const controller = new AbortController();

        if (isConnected) {
            getData(controller);

            socket.on('data', () => { getData(controller); });
        }

        return () => {
            socket.off('data');
            controller.abort();
        };
    }, [isConnected]);

    if (user.admin) {
        if (columns !== null && rows !== null) {
            return (
                <>
                    <Backdrop
                        sx={{ color: '#fff', zIndex: 10000000 }}
                        open={broker_loading}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                    <Paper elevation={1}>
                        <div style={{ height: '80vh', width: '100%' }}>
                            <DataGridPro
                                slots={{
                                    toolbar: GridToolbar
                                }}
                                slotProps={{
                                    toolbar: {
                                        showQuickFilter: true,
                                        quickFilterProps: { debounceMs: 500 }
                                    }
                                }}
                                rows={rows}
                                columns={columns}
                                pagination
                            />
                        </div>
                    </Paper>
                </>
            );
        } else {
            return (
                <Box
                    component="main"
                    sx={{ flexGrow: 1, p: 3, alignItems: 'center', textAlign: 'center' }}
                >
                    <CircularProgress size={60} thickness={4} />
                </Box>
            );
        }
    } else {
        return (
            <Box
                component="main"
                sx={{ flexGrow: 1, p: 3, alignItems: 'center', textAlign: 'center' }}
            >
                <WarningRoundedIcon size={60} thickness={4} />
                <p>Not Authorized</p>
            </Box>
        );
    }
}

export default Commissions;